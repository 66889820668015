<template>
  <v-container fluid class="fill-height">
    <v-row>
      <v-col cols="12">
        <v-row
          align="center"
          justify="center"
        >
          <div class="text-h3">404.That's an error</div>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <div class="text-h6">Page not found!</div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NotFoundView'
}
</script>
